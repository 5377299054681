import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { LinkOutlined, EnvironmentOutlined, AreaChartOutlined, AppstoreOutlined} from '@ant-design/icons';
import './FlatList.css';
import { Col, Row } from "antd";
import Icon from "@ant-design/icons"
const FlatList = () => {
    const [properties, setProperties] = useState([]);
    const [selectedType, setSelectedType] = useState('featured');
    const [isVisible, setIsVisible] = useState(false);
    const hosturl = 'https://api.bookarenthouse.com';
    const ref = useRef(null);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await fetch(`${hosturl}/api/properties`);
                const data = await response.json();
                const uniqueProperties = Array.from(new Set(data.map(prop => prop._id)))
                    .map(id => data.find(prop => prop._id === id));
                const sortedProperties = uniqueProperties.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
                setProperties(sortedProperties);
            } catch (error) {
                console.error("Error fetching properties:", error);
            }
        };

        fetchProperties();
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }, {
            threshold: 0.1, // Trigger when 10% of the element is visible
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    const filteredProperties = properties.filter(property => {
        // Only show properties that are paid
        if (property.status !== "PAID") {
            return false;
        }
        
        if (selectedType === 'featured') {
            return true;
        }
        return property.type.toLowerCase() === selectedType;
    });

    const displayedProperties = filteredProperties.slice(0, 8);

    return (
        <div ref={ref} style={{ padding: '20px' }}>
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                <h2 className={`heading ${isVisible ? 'fade-in' : ''}`}>Property Listings</h2>
                <div style={{ marginRight: 50 }}>
                    {['featured', 'commercial', 'residential'].map(type => (
                        <button 
                            key={type} 
                            onClick={() => setSelectedType(type)} 
                            className={`filter-button ${selectedType === type ? 'active' : ''} ${isVisible ? 'fade-in' : ''}`}
                        >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </button>
                    ))}
                </div>
            </div> */}

<div style={{ marginBottom: '20px',}} >
    <Row justify={'space-around'}   gutter={[16, 16]}>
        <Col xs={24} md={12} lg={8} >
            <h2 className={`heading ${isVisible ? 'fade-in' : ''}`}>Property Listings</h2>
        </Col>
        <Col xs={24} md={12} lg={16} style={{ textAlign: 'right' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {['featured', 'commercial', 'residential'].map(type => (
                    <button 
                        key={type} 
                        onClick={() => setSelectedType(type)} 
                        className={` responsive-button filter-button ${selectedType === type ? 'active' : ''} ${isVisible ? 'fade-in' : ''}`}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </button>
                ))}
            </div>
        </Col>
    </Row>
            </div>
            <p style={{width:'50%', marginLeft:50}}>
                Browse our place listings for diverse options: apartments, houses, villas, townhouses, commercial shops, offices, and more to fit your needs perfectly.
            </p>
            <div className="property-list">
                {displayedProperties.map((property, index) => (
                    <div key={property._id} className={`property-card ${isVisible ? 'fade-in' : 'fade-out'} ${index % 2 === 0 ? 'from-left' : 'from-right'}`}>
                        <div className="card-content">
                            <div className="property-image-container">
                                <img 
                                    className="property-image" 
                                    src={property.photos.length > 0 ? `${hosturl}/${property.photos[0]}` : '/path/to/default/image.jpg'} 
                                    alt="flat"
                                />
                                <div className="overlay"></div>
                                <Link to={`/flat/${property._id}`} className="link-icon">
                                    <LinkOutlined />
                                </Link>
                            </div>
                            <div className="property-info">
                                <div className="property-header">
                                    <h4 className="property-title">{property.type}</h4>
                                </div>
                                <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between"}} className="property-details">
                                    <p><AreaChartOutlined /> {property.area} sq ft</p>
                                    <p><EnvironmentOutlined /> {property.location}</p>
                                </div>
                                <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between"}} className="property-details">
                                    <p><AppstoreOutlined /> {property.face} </p>
                                    <p  className="property-price" style={{fontSize:'1.2rem'}} >₹{property.rent}</p>
                                    </div>
                                <div style={{display:"flex", justifyContent:'flex-end',marginTop:10,}}>
</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FlatList;
